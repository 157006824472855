/*
 * @Description:
 * @Autor: 蒋雪
 * @Date: 2022-12-12 11:55:29
 * @LastEditors: 蒋雪
 * @LastEditTime: 2022-12-12 11:55:38
 */
import Vue from "vue";
import DialogComponent from "./Dialog";

let instance;

function isInDocument(element) {
  return document.body.contains(element);
}

function initInstance() {
  if (instance) {
    instance.$destroy();
  }

  instance = new (Vue.extend(DialogComponent))({
    el: document.createElement("div"),
  });

  document.body.appendChild(instance.$el);

  instance.$on("input", (value) => {
    !value && Dialog.resetOptions(Dialog.defaultOptions);
    instance.value = value;
  });
}

function Dialog(options) {
  return new Promise((resolve, reject) => {
    if (!instance || !isInDocument(instance.$el)) {
      initInstance();
    }
    window.android?.backBtnShow?.(false);
    Object.assign(instance, Dialog.defaultOptions, options, {
      resolve,
      reject,
    });
  });
}

Dialog.defaultOptions = {
  value: true,
  type: "",
  data: null,
  position: "center",
  overlayStyle: {},
  beforeClose: null,
  callback: (action, type) => {
    window.android?.backBtnShow?.(true);
    try {
      instance["resolve"]({ action, type });
    } catch (error) {
      instance["reject"]({
        action: "error",
        type: JSON.stringify(error) || "未知错误",
      });
    }
  },
};

Dialog.close = () => {
  if (instance) {
    window.android?.backBtnShow?.(true);
    instance.value = false;
  }
};
Dialog.install = () => {
  Vue.use(DialogComponent);
};
Dialog.resetOptions = (options) => {
  Object.assign(Dialog.defaultOptions, options);
};

Dialog.Component = DialogComponent;

Vue.prototype.$dialog = Dialog;

export default Dialog;
