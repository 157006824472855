<script>
export default {
  inheritAttrs: false,
  name: "LandingDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "center",
    },
    closeOnClickOverlay: {
      type: Boolean,
      default: false,
    },
    overlayStyle: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    beforeClose: {
      type: Function,
    },
    callback: {
      type: Function,
    },
  },
  data() {
    return {
      // 弹窗队列
      queue: [],
    };
  },
  computed: {
    childComponentUrl() {
      let type = this.type;
      const [fileDir, fileName] = type.split("-");
      const url = `./${fileDir}/${fileName}.vue`;
      return url;
    },
    childComponent() {
      const url = this.childComponentUrl;
      const component = () => import(/* webpackPrefetch: true */ `${url}`);
      return component;
    },
  },
  methods: {
    handleClose(action = "close", event = null) {
      this.$emit("input", false);
      if (this.callback) {
        this.callback(action, event);
      }
    },
    onClose(event = null) {
      this.handleClose("close", event);
    },
    onConfirm(event) {
      if (this.beforeClose) {
        this.beforeClose({
          event,
          close: () => {
            this.handleClose("confirm", event);
          },
        });
      } else {
        this.handleClose("confirm", event);
      }
    },
  },
  render(h) {
    return h("div", [
      h("transition", { props: { name: "fade" } }, [
        h("div", {
          class: "dialog-overlay",
          style: {
            ...this.overlayStyle,
          },
          directives: [
            {
              name: "show",
              value: this.value,
            },
          ],
          on: {
            click: () => this.closeOnClickOverlay && this.onClose(),
          },
        }),
      ]),
      h("transition", { props: { name: this.position } }, [
        this.value
          ? h(this.childComponent, {
              class: ["dialog-content", `dialog-content--${this.position}`],
              props: {
                data: this.data,
              },
              on: {
                close: this.onClose,
                confirm: this.onConfirm,
              },
            })
          : null,
      ]),
    ]);
  },
};
</script>
<style lang="scss" scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}
.dialog-content {
  position: fixed;
  transform-origin: center center;
  z-index: 1000;
}
.dialog-content--center {
  top: 45%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.dialog-content--bottom {
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.center-enter-active,
.center-leave-active {
  transition: all 0.3s;
}
.center-enter,
.center-leave-to {
  opacity: 0.1;
  transform: translate3d(-50%, -50%, 0) scale(0.7);
}
.bottom-enter-active,
.bottom-leave-active {
  transition: all 0.3s;
}
.bottom-enter,
.bottom-leave-to {
  opacity: 0.1;
  transform: translate3d(-50%, 100%, 0);
}
</style>
